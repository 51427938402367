import type { TranslationKeys } from '~/plugins/i18n'
import { DataType } from '~/types/analytics'
import {
  CustomizationCategory,
  CustomizationField
} from '~/types/customization'

export const CUSTOMIZATION_FIELDS_DEFINITION_KEY: {
  [key in CustomizationField]: TranslationKeys
} = {
  [CustomizationField.EMPLOYEE_STANDARD_1]:
    'settings.workspace.preferences.table.standardFilter1Description',
  [CustomizationField.EMPLOYEE_STANDARD_2]:
    'settings.workspace.preferences.table.standardFilter2Description',
  [CustomizationField.EMPLOYEE_STANDARD_3]:
    'settings.workspace.preferences.table.standardFilter3Description',
  [CustomizationField.EMPLOYEE_GROUP]:
    'settings.workspace.preferences.table.employeeMainGroupDescription',
  [CustomizationField.RECRUITMENT_STANDARD_1]:
    'settings.workspace.preferences.table.standardFilter1Description',
  [CustomizationField.RECRUITMENT_STANDARD_2]:
    'settings.workspace.preferences.table.standardFilter2Description',
  [CustomizationField.RECRUITMENT_STANDARD_3]:
    'settings.workspace.preferences.table.standardFilter3Description',
  [CustomizationField.RECRUITMENT_GROUP]:
    'settings.workspace.preferences.table.recruitmentMainGroupDescription',
  [CustomizationField.IS_RECRUITMENT_AVAILABLE]:
    'settings.workspace.preferences.table.isRecruitmentDataAvailableDescription',
  [CustomizationField.HAS_TRIAL_PERIOD_DATA]:
    'settings.workspace.preferences.table.hasTrialPeriodDataDescription',
  [CustomizationField.HAS_MANAGER_DATA]:
    'settings.workspace.preferences.table.hasManagerDataDescription',
  [CustomizationField.FISCAL_YEAR_START_MONTH]:
    'settings.workspace.preferences.table.fiscalYearStartMonthDescription',
  [CustomizationField.ABSENTEEISM_TYPE_NORMALIZATION]:
    'settings.workspace.preferences.table.absenteeismNormalizationDescription',
  [CustomizationField.DEPARTURE_TYPE_NORMALIZATION]:
    'settings.workspace.preferences.table.departureNormalizationDescription'
}

export const DATA_TYPE_BY_CUSTOMIZATION_CATEGORY: {
  [key in CustomizationCategory]: DataType | undefined
} = {
  [CustomizationCategory.EMPLOYEE_DASHBOARD]: DataType.EMPLOYEE,
  [CustomizationCategory.RECRUITMENT_DASHBOARD]: DataType.RECRUITMENT,
  [CustomizationCategory.SETUP]: undefined
}

export const FISCAL_YEAR_START_MONTH_DEFAULT_OPTION = '1'
